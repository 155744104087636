import React from "react"
import {
  BlogContainer,
  Row,
  SubTitleSection,
  Text,
} from "../components/atomics"
import { Link } from "gatsby"
import Page from "../components/Pages/Page"
import colors from "../colors"
import { URL_EMAIL, URL_INSTA_FLYNOW, URL_WHATSAPP } from "../constants"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"

const BlackFriday = () => {
  const images = {
    apps: [
      require("../assets/images/logo.png"),
      require("../assets/images/app/finances/logo.png"),
      require("../assets/images/app/frases/logo.png"),
    ],
    ebooks: [
      require("../assets/images/ebookProdutividade.png"),
      require("../assets/images/ebookHabitos.png"),
      require("../assets/images/ebookMetas.png"),
      require("../assets/images/cursoHabitos.png"),
    ],
  }
  const combo = [
    {
      title: "➡️ [50%OFF] 🔥 COMBO: 3 APPS + 3 EBOOKS + CURSO",
      description: (
        <>
          <b>Aplicativos</b>: Flynow Produtividade + Flynow Finanças Pessoais +
          Flynow Frases de Motivação.
          <br />
          <b>Ebooks</b>: Guia Definitivo para a Produtividade + Guia completo
          sobre Hábitos + Metas & Planejamento. <br />
          <b>Curso</b>: Domine seus hábitos: A chave para a uma Vida
          Extraordinária.
        </>
      ),
      url: "/combo-apps-ebooks-curso",
      imgs: Object.values(images).flat(),
    },
    {
      title: "➡️ [40%OFF] 🔥 COMBO: 3 APPS",
      description: (
        <>
          <b>Acesso Premium Vitalício</b> aos aplicativos: Flynow Produtividade,
          Flynow Finanças Pessoais e Flynow Frases de Motivação.
        </>
      ),
      url: "/combo-apps",
      imgs: Object.values(images.apps),
    },
    {
      title: "➡️ [40%OFF] 🔥 COMBO: 3 EBOOKS + CURSO",
      description: (
        <>
          <b>Ebooks</b>: Guia Definitivo para a Produtividade + Guia completo
          sobre Hábitos + Metas & Planejamento. <br />
          <b>Curso</b>: Domine seus hábitos: A chave para a uma Vida
          Extraordinária.
        </>
      ),
      url: "/combo-ebooks-curso",
      imgs: Object.values(images.ebooks),
    },
    {
      title: "➡️ [20%OFF] 🔥 COMPRA INDIVIDUAL",
      description: (
        <>
          <b>Desconto de 20%</b> na <b>compra individual</b> de qualquer um de
          nossos produtos (apps, ebooks e curso).
        </>
      ),
      url: "/black-friday-compra-individual",
      imgs: Object.values(images).flat(),
    },
  ]

  const description =
    "Chegou a Black Flynow Flynow, recheada de ofertas especiais para você! Até o dia 30/11, todos nossos produtos estarão com um super desconto, para ajudar você a alcançar sua melhor versão. Então corre que as ofertas são por tempo limitado!"
  const language = "pt"
  return (
    <Page title={"Black Flynow"} description={description} pageId="blackWeek">
      <BlogContainer>
        <Text style={{ fontWeight: "500", lineHeight: "1.2rem" }}>
          Começou a <b>Black Flynow</b>, recheada de{" "}
          <b>descontos IRRESISTÍVEIS</b> para te ajudar a ser a{" "}
          <b>sua melhor versão</b>! 🚀✨ <br />
          <br /> 🔥 Mas corre, pois os descontos são válidos{" "}
          <b>
            <u>somente até o dia 30/11</u>
          </b>
          .
        </Text>
        <br />
        <div className="border" />

        <SubTitleSection>Nossas promoções:</SubTitleSection>
        {combo.map(item => (
          <div style={{ marginTop: "1.8rem" }}>
            <Link
              to={item.url}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h3 style={{ color: colors.primary2 }}>{item.title}</h3>
              {item.imgs.map(i => (
                <img
                  src={i}
                  height={70}
                  style={{ marginRight: 5, marginTop: "0.7rem" }}
                />
              ))}
              <span style={{ display: "block", marginBottom: 5 }}>
                {item.description}
              </span>
              <button
                style={{
                  cursor: "pointer",
                  color: colors.white,
                  backgroundColor: colors.primary2,
                  border: "none",
                  padding: "5px 20px",
                  borderRadius: 8,
                  fontSize: "0.8rem",
                }}
              >
                Ver mais
              </button>
            </Link>
          </div>
        ))}

        <br />
        <br />
        <h3 style={{ textAlign: "center" }}>Alguma dúvida?</h3>
        <br />
        <span style={{ textAlign: "center" }}>
          Se tiver qualquer dúvida entre em contato conosco!
        </span>
        {[
          {
            link: URL_WHATSAPP,
            img: <FaWhatsapp />,
            description: "31 999107753",
          },
          {
            link: URL_EMAIL,
            img: <MdMailOutline />,
            description: "support@appflynow.com",
          },
          {
            link: URL_INSTA_FLYNOW,
            img: <FaInstagram />,
            description: "@appflynow",
          },
        ].map(i => (
          <a
            href={i.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              alignSelf: "center",
              textDecoration: "none",
              color: "inherit",
              fontSize: "2rem",
              color: colors.primary2,
            }}
          >
            <Row style={{ marginTop: 10, alignItems: "center" }}>
              {i.img}
              <h5>{i.description}</h5>
            </Row>
          </a>
        ))}
      </BlogContainer>
      <br />
      <br />
    </Page>
  )
}

export default BlackFriday
